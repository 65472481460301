::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba($gray-500, 1);
}

::-webkit-scrollbar-track {
  background: rgba($gray-300, 1);
}
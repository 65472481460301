$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$gold: #926A14 !default;
$blue: #21215A !default;
$cyan: #515BB3 !default;
$red: #B9525A !default;
$orange: #ED7250 !default;
$yellow: #F3984B !default;
$purple: #645892 !default;
$green: #51b87c !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$gazel-blue: #0082f3 !default;
$gazel-green: #00c350 !default;

$primary: $gazel-green !default;
$secondary: $gazel-blue !default;
$success: $gold !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $gray-800 !default;

$white: #FFFFFF !default;

$border-radius: .123rem !default;
$border-radius-lg: .15rem !default;
$border-radius-sm: .1rem !default;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: false !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

:export {
  fontFamily: $font-family-sans-serif;

  blue: $blue;
  gold: $gold;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  cyan: $cyan;
  purple: $purple;
  green: $green;

  gazelblue: $gazel-blue;
  gazelgreen: $gazel-green;

  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;

  primary: $primary;
  secondary: $secondary;
  success: $success;
  info: $info;
  warning: $warning;
  danger: $danger;
  light: $light;
  dark: $dark;
  white: $white;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn .4s ease 1 both;
}

.fadeOut {
  animation: fadeOut .4s linear 1 both;
}

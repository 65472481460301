.loadingLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    z-index: 2;
  }
  
  .spinner {
    position: relative;
    top: 35%;
    width: 40px;
    height: 40px;
    border-width: 6px;
    color:#926A14CC;
  }
  